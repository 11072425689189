import React from 'react';
import { StyledNoResults } from './NoResults.styles';

type Props = {
  clearFilters?: () => void;
  hasFiltersApplied?: boolean;
  message: string;
};

export function NoResults({ clearFilters, hasFiltersApplied, message }: Props) {
  return (
    <StyledNoResults>
      <p>{message}</p>
      {hasFiltersApplied && (
        <p>
          <span onClick={clearFilters}>Click here</span> to reset the filters.
        </p>
      )}
    </StyledNoResults>
  );
}
