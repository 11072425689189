import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { TYPOGRAPHY, COLORS } from '../../constants';

const GlobalOverrideStyles = createGlobalStyle`
:root,
:root [data-theme=light],
:root [data-theme=dark] {
    --pf-font-color: ${COLORS.defaultFontColor};    
    --pf-font-family-base: ${TYPOGRAPHY.fontFamily.base};

    .theme {
      // Needed a bit more specificity to override the design system (dont love this but eh)
      --pf-font-color: ${COLORS.defaultFontColor};
      --pf-font-family-base: ${TYPOGRAPHY.fontFamily.base};

      background-color: var(--app-background);
      
    }
  }
`;

export const DesignSystemOverrides = () => {
  return <GlobalOverrideStyles />;
};
