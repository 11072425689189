import { COLORS, TYPOGRAPHY } from 'Permafrost/constants';
import styled from 'styled-components';

export const StyledActionBar = styled.div<{ isFixed: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  position: fixed;
  bottom: 100px;
  left: ${(props) => (props.isFixed ? '50%' : 0)};

  border-radius: 8px;
  border: 3px solid ${COLORS.portGore};

  background-color: ${COLORS.midnight};
  padding: 10px;
`;

export const StyledTextContainer = styled.div`
  padding: 10px 8px;

  span {
    font-size: ${TYPOGRAPHY.fontSize.subheadSmall};
    font-weight: 500;
    line-height: 24px;
    color: ${COLORS.white};
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
