import React from 'react';
import { StyledIcon, StyledPageNumber, StyledPagination, StyledText } from './Pagination.styles';

type Props = {
  totalCount: number;
  currentPage: number;
  nextPage: () => void;
  previousPage: () => void;
};

export function Pagination({ totalCount, currentPage, nextPage, previousPage }: Props) {
  const isLastPage = currentPage === totalCount - 1;
  const isFirstPage = currentPage === 0;

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      previousPage();
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalCount - 1) {
      nextPage();
    }
  };

  return (
    <StyledPagination>
      <StyledIcon name="chevron-left" onClick={handlePreviousPage} isDisabled={isFirstPage} />
      <StyledText>
        Page{' '}
        <StyledPageNumber>
          <span>{currentPage + 1}</span>
        </StyledPageNumber>
        of {totalCount}
      </StyledText>
      <StyledIcon name="chevron-right" onClick={handleNextPage} isDisabled={isLastPage} />
    </StyledPagination>
  );
}
