import React from 'react';
import { Row, Table } from '@tanstack/react-table';

import { StyledActionBar, StyledButtonContainer, StyledTextContainer } from './ActionBar.styles';

type Props<T extends object> = {
  table: Table<T & { id: string }>;
  isFixed: boolean;
  TableActions?: React.ComponentType<{ selectedItems: Row<any>[]; unselectRows: () => void }>;
};

export function ActionBar<T extends object>({ table, isFixed, TableActions }: Props<T>) {
  const selectedItems = table.getRowModel().rows.filter((row) => row.getIsSelected());

  const unselectRows = () => {
    table.getRowModel().rows.forEach((row) => {
      row.toggleSelected(false);
    });
  };

  return (
    <StyledActionBar isFixed={isFixed}>
      <StyledTextContainer>
        <span>
          {selectedItems.length} {selectedItems.length > 1 ? 'items' : 'item'} selected
        </span>
      </StyledTextContainer>
      <StyledButtonContainer>
        {TableActions && <TableActions selectedItems={selectedItems} unselectRows={unselectRows} />}
      </StyledButtonContainer>
    </StyledActionBar>
  );
}
