import React from 'react';

import classNames from 'classnames';

import { StyledToggle } from './Toggle.styles';

// todo: convert to ts; resolve a11y concerns (e.g.g display: none)

export const Toggle = (props) => {
  const { className, disabled, id, onChange, value } = props;

  return (
    <StyledToggle
      className={classNames(className, {
        disabled,
      })}
      data-cy={props['data-cy']}
      data-for={props['data-for']}
      data-tip={props['data-tip']}
      id={id}
    >
      <input
        aria-label={props['aria-label']}
        type="checkbox"
        disabled={disabled}
        checked={value}
        onChange={onChange}
      />
      <span className="slider round" />
    </StyledToggle>
  );
};
