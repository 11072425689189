import React, { ChangeEvent, useRef } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

import { v4 as uuid } from 'uuid';

import { Button, Icon } from 'Permafrost/index';
import { PermafrostComponent } from 'Permafrost/types';

import { StyledNumberField } from './NumberField.styles';

export type Props = PermafrostComponent & {
  autoFocus?: boolean;
  disabled?: boolean;
  hiddenLabel?: boolean;
  label: string;
  max?: number;
  min?: number;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  step?: number;
  tabIndex?: number;
  value: number | null;
  onChange(value: number | null): void; // returned value can be outside min/max
  onClick?: () => void;
};

/**
 * Basic numeric input field. A label is required, but may be visually hidden
 * using the `hiddenLabel` property.
 */
export function NumberField(props: Props): React.ReactElement {
  // ensures unique value to associate label with input
  const fieldId = uuid();
  const {
    autoFocus,
    className,
    disabled,
    hiddenLabel,
    id,
    label,
    max,
    min,
    placeholder,
    readOnly = false,
    required,
    step,
    tabIndex,
    value,
    onChange,
    onClick,
  } = props;

  const inputEl = useRef<HTMLInputElement>(null);

  const increment = () => {
    const input = inputEl.current;
    if (input) {
      input.stepUp();
      // if !value OR value < min, set value to min or 1 - else increment by step
      isNil(value) || (!isNil(min) && value < min) ? onChange(min || 1) : onChange(+input.value);
    }
  };

  const decrement = () => {
    const input = inputEl.current;
    if (input) {
      input.stepDown();
      // if !value, set value to min or 0 - else if value > max, set value to max - else decrement by step
      isNil(value)
        ? onChange(min || 0)
        : !isNil(max) && value > max
        ? onChange(max)
        : onChange(+input.value);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // no validation for min or max - whatever value the user enters will be passed to onChange
    const targetValue = event?.target?.value;
    onChange(targetValue === '' ? null : +targetValue);
  };

  return (
    <StyledNumberField
      className={classNames(className, {
        hiddenLabel,
      })}
      data-cy={props['data-cy']}
      id={id}
      onClick={onClick}
    >
      <input
        id={fieldId}
        aria-label={hiddenLabel ? label : ''}
        autoFocus={autoFocus}
        data-testid={props['data-testid']}
        readOnly={readOnly}
        disabled={disabled}
        max={max}
        min={min}
        onChange={handleChange}
        placeholder={placeholder}
        ref={inputEl}
        required={required}
        step={step}
        tabIndex={tabIndex}
        type="number"
        value={!isNil(value) ? value : ''}
      />

      {!hiddenLabel ? <label htmlFor={fieldId}>{label}</label> : null}

      {/* since user can increment/decrement using arrow keys, these don’t need to be tabbable */}
      {readOnly ? null : (
        <div className="spin-buttons">
          <Button
            data-testid="increment-button"
            disabled={!isNil(value) && !isNil(max) && value >= max ? true : false}
            onClick={increment}
            tabindex={-1}
            variant="no-style"
          >
            <Icon name="fa-caret-up" ariaLabel="increase" />
          </Button>

          <Button
            data-testid="decrement-button"
            disabled={!isNil(value) && !isNil(min) && value <= min ? true : false}
            onClick={decrement}
            tabindex={-1}
            variant="no-style"
          >
            <Icon name="fa-caret-down" ariaLabel="decrease" />
          </Button>
        </div>
      )}
    </StyledNumberField>
  );
}
