/**
 * Trigger a file download programmatically by creating and utilizing a temporary `<a>` element.
 *
 * @example
 * downloadFile('https://dev.indico.io/my-file.csv')
 */
export function downloadFile(downloadUrl: string, filename?: string): void {
  const linkEl = document.createElement('a');

  linkEl.setAttribute('href', downloadUrl);
  linkEl.setAttribute('download', filename ?? '');
  linkEl.style.display = 'none';

  document.body.appendChild(linkEl);

  linkEl.click();

  document.body.removeChild(linkEl);
}
