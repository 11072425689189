import { Icon } from 'Permafrost/components/ui-foundations/Icon/Icon';
import { COLORS, TYPOGRAPHY } from 'Permafrost/constants';
import styled from 'styled-components';

export const StyledPagination = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 15px;

  margin-top: 10px;
  margin-right: 24px;
`;

export const StyledText = styled.span`
  font-size: ${TYPOGRAPHY.fontSize.base};
  line-height: 20px;
  color: #8593b3;
  user-select: none;

  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StyledPageNumber = styled.span`
  border: 1px solid #198bdc;
  border-radius: 2px;

  height: 24px;
  width: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  span {
    font-size: ${TYPOGRAPHY.fontSize.base};

    font-size: ${TYPOGRAPHY.fontSize.base};
    font-weight: 700;
    line-height: 20px;
    color: ${COLORS.white};
  }
`;
export const StyledIcon = styled(Icon)<{ isDisabled?: boolean }>`
  height: 14px;
  width: 14px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'normal' : 'pointer')};

  &:hover {
    opacity: ${({ isDisabled }) => (isDisabled ? '1' : '0.5')};
  }
`;
